<template>
  <div>
    <dashboard-top-menu
      :isCompanyDashboard="false"
      :applicationId="applicationId"
      @set-selected-dashboard="setSelectedDashboard"
      @open-add-widget="showAddWigetPopup=true"
    />

    <dashboard-widget-cards 
      v-if="activeDashboard"
      :widgetList="widgetList"
      :dashboardId="activeDashboard.id"
      :isCompanyDashboard="false"
      :applicationId="parseInt(applicationId)"
      :isDashboardReadonly="isDashboardReadonly"
      :isDashboardDefault="isDashboardDefault"
    />

  </div>
</template>

<script>
import DashboardWidgetPopup from './DashboardWidgetPopup.vue'
import applicationDashboardStore from './applicationDashboardStore.js'
import DashboardTopMenu from './components/DashboardTopMenu.vue'
import DashboardWidgetCards from './components/DashboardWidgetCards.vue'

export default {
  components: {
    DashboardWidgetPopup,
    DashboardTopMenu,
    DashboardWidgetCards
  },
  props: {
    applicationId: { required: true },
    dashboardId: {required: false }
  },
  data () {
    return {
      resolvingAppId: false
    }
  },
  computed: {
    activeDashboard () {
      return this.$store.state.applicationDashboardStore.activeDashboard
    },
    widgetList () {
      return this.$_.sortBy(this.$store.state.applicationDashboardStore.widgets, ['orderId'])
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isDashboardReadonly () {
      return this.$store.state.applicationDashboardStore.activeDashboard 
        && this.$store.state.applicationDashboardStore.activeDashboard.applicationId === 0
    },
    isDashboardDefault () {
      return this.$store.state.applicationDashboardStore.activeDashboard 
        && this.$store.state.applicationDashboardStore.activeDashboard.applicationId === 0
    },
    activeDashboardId () {
      return (this.activeDashboard && this.activeDashboardId.id) || 0
    }
  },
  created () {

    //Component is loaded via url from address bar, applicationId prop will conflict with ApplicationId in $store, so update store
    if(this.applicationId != this.$store.state.ActiveApplicationId) {
      const activeApp = this.$_.find(this.$store.state.Applications, app => app.id == this.applicationId)
      this.resolvingAppId = true

      //Check if user has acccess to the app
      const activeUser = this.$store.state.AppActiveUser;
      if(activeUser.applications && activeUser.applications.find(x => x.id == this.applicationId)) {
        this.$store.commit('UPDATE_SKIP_REDIRECT_AFTER_APP_SWITCH', true)
        this.$store.commit('UPDATE_ACTIVE_APP', {
          id: parseInt(this.applicationId),
          name: activeApp.name,
          features: activeApp.features || [],
          appStatsUrl: activeApp.appStatsUrl,
          appStatsUrlLink: activeApp.appStatsUrlLink,
         })
      } else {
        this.$router.push('/error-404')
      }
      
    }

    this.$vs.loading()
    this.$store.dispatch('applicationDashboardStore/fetchDashboards', { applicationId: this.applicationId })
    .then(response => {
      if (this.dashboardId) {
        const val = this.$_.find(this.$store.state.applicationDashboardStore.dashboards, x =>
          x.id == this.dashboardId);
        this.$store.dispatch('applicationDashboardStore/persistActiveDashboard', val)
      }
      else if (!this.$store.state.applicationDashboardStore.activeDashboard) {
        this.$store.dispatch('applicationDashboardStore/setActiveDashboardToDefault')
      }

      this.fetchWidgets()
        .finally(() => this.$vs.loading.close())
    })
    .catch(error => console.error(error))
    
  },
  methods: {
    fetchWidgets() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`applicationDashboardStore/fetchWidgets`, { 
          applicationId: this.applicationId,
          dashboardId: (this.activeDashboard && this.activeDashboard.id) || this.dashboardId
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    setSelectedDashboard (value) {
      const val = this.$_.find(this.$store.state.applicationDashboardStore.dashboards, x =>
        x.id === value.code);
      this.$store.dispatch('applicationDashboardStore/persistActiveDashboard', val)

      this.$vs.loading()
      this.fetchWidgets()
        .finally(() => this.$vs.loading.close())  
    }
    
  }
}
</script>